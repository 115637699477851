export const tooltipInfo = {
  "ERC 20 Compliant": "Information",
  Mintable: "Information",
  "Liquidity Generator": "Information",
  "Verified Source Code": "Information",
  Burnable: "Information",
  "Donation (Charity)": "Information",
  Ownable: "Information",
  Pausable: "Information",
  "Yield Generator": "Information",
  "Capped Total Supply": "Information",
  "Taxable (Burn Tax)": "Information",
};

export const checkboxTooltipInfo = {
  Mintable: "Mintable",
  Burnable: "Burnable",
  Pausable: "Pausable",
  Capped: "Capped Total Supply",
  "Liquidity Generator": "Liquidity Generator",
  "Yield Generator": "Yield Generator",
  "Donation (Charity)": "Donation (Charity)",
  "Burn Tax": "Taxable (Burn Tax)",
}

export const sampleTokenTypeData = [
  {
    id: "f01",
    name: "Standard Token",
    description: "Token Description",
    features: {
      "ERC 20 Compliant": true,
      Mintable: false,
      "Liquidity Generator": false,
      "Verified Source Code": true,
      Burnable: false,
      "Donation (Charity)": false,
      Ownable: true,
      Pausable: false,
      "Yield Generator": false,
      "Capped Total Supply": false,
      "Taxable (Burn Tax)": false,
    },
    advancedFeatures: "00000000",
    reflective: false
  },
  {
    id: "f02",
    name: "Mintable Token",
    description: "Token Description",
    features: {
      "ERC 20 Compliant": true,
      Mintable: true,
      "Liquidity Generator": false,
      "Verified Source Code": true,
      Burnable: false,
      "Donation (Charity)": false,
      Ownable: true,
      Pausable: false,
      "Yield Generator": false,
      "Capped Total Supply": false,
      "Taxable (Burn Tax)": false,
    },
    advancedFeatures: "00000000",
    reflective: false
  },
  {
    id: "f03",
    name: "Burnable Token",
    description: "Token Description",
    features: {
      "ERC 20 Compliant": true,
      Mintable: false,
      "Liquidity Generator": false,
      "Verified Source Code": true,
      Burnable: true,
      "Donation (Charity)": false,
      Ownable: true,
      Pausable: false,
      "Yield Generator": false,
      "Capped Total Supply": false,
      "Taxable (Burn Tax)": false,
    },
    advancedFeatures: "00000000",
    reflective: false
  },
  {
    id: "f04",
    name: "Mintable & Burnable Token",
    description: "Token Description",
    features: {
      "ERC 20 Compliant": true,
      Mintable: true,
      "Liquidity Generator": false,
      "Verified Source Code": true,
      Burnable: true,
      "Donation (Charity)": false,
      Ownable: true,
      Pausable: false,
      "Yield Generator": false,
      "Capped Total Supply": false,
      "Taxable (Burn Tax)": false,
    },
    advancedFeatures: "00000000",
    reflective: false
  },
  {
    id: "f05",
    name: "Pausable Standard Token",
    description: "Token Description",
    features: {
      "ERC 20 Compliant": true,
      Mintable: false,
      "Liquidity Generator": false,
      "Verified Source Code": true,
      Burnable: false,
      "Donation (Charity)": false,
      Ownable: true,
      Pausable: true,
      "Yield Generator": false,
      "Capped Total Supply": false,
      "Taxable (Burn Tax)": false,
    },
    advancedFeatures: "00000000",
    reflective: false
  },
  {
    id: "f06",
    name: "Pausable Mintable Token",
    description: "Token Description",
    features: {
      "ERC 20 Compliant": true,
      Mintable: true,
      "Liquidity Generator": false,
      "Verified Source Code": true,
      Burnable: false,
      "Donation (Charity)": false,
      Ownable: true,
      Pausable: true,
      "Yield Generator": false,
      "Capped Total Supply": false,
      "Taxable (Burn Tax)": false,
    },
    advancedFeatures: "00000000",
    reflective: false
  },
  {
    id: "f07",
    name: "Pausable Burnable Token",
    description: "Token Description",
    features: {
      "ERC 20 Compliant": true,
      Mintable: false,
      "Liquidity Generator": false,
      "Verified Source Code": true,
      Burnable: true,
      "Donation (Charity)": false,
      Ownable: true,
      Pausable: true,
      "Yield Generator": false,
      "Capped Total Supply": false,
      "Taxable (Burn Tax)": false,
    },
    advancedFeatures: "00000000",
    reflective: false
  },
  {
    id: "f08",
    name: "Pausable Mint&Burnable Token",
    description: "Token Description",
    features: {
      "ERC 20 Compliant": true,
      Mintable: true,
      "Liquidity Generator": false,
      "Verified Source Code": true,
      Burnable: true,
      "Donation (Charity)": false,
      Ownable: true,
      Pausable: true,
      "Yield Generator": false,
      "Capped Total Supply": false,
      "Taxable (Burn Tax)": false,
    },
    advancedFeatures: "00000000",
    reflective: false
  },
  {
    id: "s09",
    name: "Capped Mintable Token",
    description: "Token Description",
    features: {
      "ERC 20 Compliant": true,
      Mintable: true,
      "Liquidity Generator": false,
      "Verified Source Code": true,
      Burnable: false,
      "Donation (Charity)": false,
      Ownable: true,
      Pausable: false,
      "Yield Generator": false,
      "Capped Total Supply": true,
      "Taxable (Burn Tax)": false,
    },
    advancedFeatures: "11000000",
    reflective: false
  },
  {
    id: "s10",
    name: "Capped Mint&Burnable Token",
    description: "Token Description",
    features: {
      "ERC 20 Compliant": true,
      Mintable: true,
      "Liquidity Generator": false,
      "Verified Source Code": true,
      Burnable: true,
      "Donation (Charity)": false,
      Ownable: true,
      Pausable: false,
      "Yield Generator": false,
      "Capped Total Supply": true,
      "Taxable (Burn Tax)": false,
    },
    advancedFeatures: "11000000",
    reflective: false
  },
  {
    id: "s11",
    name: "Capped Pausable Mintable Token",
    description: "Token Description",
    features: {
      "ERC 20 Compliant": true,
      Mintable: true,
      "Liquidity Generator": false,
      "Verified Source Code": true,
      Burnable: false,
      "Donation (Charity)": false,
      Ownable: true,
      Pausable: true,
      "Yield Generator": false,
      "Capped Total Supply": true,
      "Taxable (Burn Tax)": false,
    },
    advancedFeatures: "11000000",
    reflective: false
  },
  {
    id: "s12",
    name: "Capped Pausable Mint&Burnable Token",
    description: "Token Description",
    features: {
      "ERC 20 Compliant": true,
      Mintable: true,
      "Liquidity Generator": false,
      "Verified Source Code": true,
      Burnable: true,
      "Donation (Charity)": false,
      Ownable: true,
      Pausable: true,
      "Yield Generator": false,
      "Capped Total Supply": true,
      "Taxable (Burn Tax)": false,
    },
    advancedFeatures: "11000000",
    reflective: false
  },
  {
    id: "s13",
    name: "Taxable Token",
    description: "Token Description",
    features: {
      "ERC 20 Compliant": true,
      Mintable: false,
      "Liquidity Generator": false,
      "Verified Source Code": true,
      Burnable: false,
      "Donation (Charity)": false,
      Ownable: true,
      Pausable: false,
      "Yield Generator": true,
      "Capped Total Supply": true,
      "Taxable (Burn Tax)": false,
    },
    advancedFeatures: "00100000",
    reflective: true
  },
  {
    id: "s14",
    name: "AutoLP Taxable Token",
    description: "Token Description",
    features: {
      "ERC 20 Compliant": true,
      Mintable: false,
      "Liquidity Generator": true,
      "Verified Source Code": true,
      Burnable: false,
      "Donation (Charity)": false,
      Ownable: true,
      Pausable: false,
      "Yield Generator": true,
      "Capped Total Supply": true,
      "Taxable (Burn Tax)": false,
    },
    advancedFeatures: "00101010",
    reflective: true
  },
  {
    id: "s15",
    name: "Deflationary Token",
    description: "Token Description",
    features: {
      "ERC 20 Compliant": true,
      Mintable: false,
      "Liquidity Generator": false,
      "Verified Source Code": true,
      Burnable: false,
      "Donation (Charity)": false,
      Ownable: true,
      Pausable: false,
      "Yield Generator": true,
      "Capped Total Supply": true,
      "Taxable (Burn Tax)": true,
    },
    advancedFeatures: "00110000",
    reflective: true
  },
  {
    id: "s16",
    name: "AutoLP Deflationary Token",
    description: "Token Description",
    features: {
      "ERC 20 Compliant": true,
      Mintable: false,
      "Liquidity Generator": true,
      "Verified Source Code": true,
      Burnable: false,
      "Donation (Charity)": false,
      Ownable: true,
      Pausable: false,
      "Yield Generator": true,
      "Capped Total Supply": true,
      "Taxable (Burn Tax)": true,
    },
    advancedFeatures: "00111010",
    reflective: true
  },
  {
    id: "s17",
    name: "Super Deflationary Token",
    description: "Token Description",
    features: {
      "ERC 20 Compliant": true,
      Mintable: false,
      "Liquidity Generator": true,
      "Verified Source Code": true,
      Burnable: false,
      "Donation (Charity)": true,
      Ownable: true,
      Pausable: false,
      "Yield Generator": true,
      "Capped Total Supply": true,
      "Taxable (Burn Tax)": true,
    },
    advancedFeatures: "00111111",
    reflective: true
  },
];

