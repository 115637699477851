export const TRANSLATIONS_EN = {
	welcome: "Welcome to the dapp",
	get_started: "Get Started",
	planning_to_launch: "Planning to",
	planning_to_launch_ico: "Launch an ICO?",
	start_vesting: "Start vesting tokens today",
	already_vested: "Already Vested? Explore.",
	dashboard: "Dashboard",
	please_connect_metamask: "Please Connect your",
	please_connect_metamask_2: "Metamask Wallet to proceed",
	connect_metamask: "Connect Metamask",
	install_metamask: "Install Metamask",
	vest_token_steps: "Vest Tokens in 6 simple steps",
	vest_token_step_1: "1. Enter your Contract Address",
	vest_token_step_2: "2. Enter your Project Details",
	vest_token_step_3: "3. Download Vesting Sheet Template",
	vest_token_step_4: "4. Upload your Vesting Sheet",
	vest_token_step_5: "5. Review your Vesting Sheet",
	vest_token_step_6: "6. Approve & Lock Vesting Sheet",
	begin_vesting: "Begin Vesting",
	enter_contract_address: "Enter Contract Address",
	contract_address: "Contract Address",
	enter_project_details: "Enter Project Details",
	project_name: "Project Name",
	project_description: "Project Description",
	download_vesting_sheet: "Download Vesting Sheet Template",
	recommended_sheet_text:
		"To proceed further with an error-free vesting experience we recommend adding the token details in the following template.",
	upload_vesting_sheet: "Upload Vesting Sheet",
	review: "Review",
	total_amount: "Total Amount",
	lock_vesting_sheet: "Lock Vesting Sheet",
	approve: "Approve",
	lock_token: "Lock ETT",
	page_not_found: "Sorry, this page isn't available.",
	nothing_here: "Sorry, there is nothing here.",
};
