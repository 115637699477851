export const PinataAPIKey = process.env.REACT_APP_PINATA_API_KEY;
export const PinataSecretKey = process.env.REACT_APP_PINATA_API_SECRET;

export const EXPLORER_ETHEREUM = process.env.REACT_APP_EXPLORER_ETHEREUM;
export const EXPLORER_BSC = process.env.REACT_APP_EXPLORER_BSC;
export const EXPLORER_MATIC = process.env.REACT_APP_EXPLORER_MATIC;
export const EXPLORER_AVALANCHE = process.env.REACT_APP_EXPLORER_AVALANCHE;

export const MATIC_CHAIN_ID = process.env.REACT_APP_MATIC_CHAIN_ID;
export const BSC_CHAIN_ID = process.env.REACT_APP_BSC_CHAIN_ID;
export const ETHEREUM_CHAIN_ID = process.env.REACT_APP_ETHEREUM_CHAIN_ID;
export const AVALANCHE_CHAIN_ID = process.env.REACT_APP_AVALANCHE_CHAIN_ID;

export const CAPX_FACTORY_ADDRESS_ETHEREUM = process.env.REACT_APP_CAPX_FACTORY_ADDRESS_ETHEREUM;
export const CAPX_FACTORY_ADDRESS_BSC = process.env.REACT_APP_CAPX_FACTORY_ADDRESS_BSC;
export const CAPX_FACTORY_ADDRESS_MATIC = process.env.REACT_APP_CAPX_FACTORY_ADDRESS_MATIC;
export const CAPX_FACTORY_ADDRESS_AVALANCHE = process.env.REACT_APP_CAPX_FACTORY_ADDRESS_AVALANCHE;

export const GRAPH_FETCH_ETHEREUM = process.env.REACT_APP_GRAPH_FETCH_ETHEREUM;
export const GRAPH_FETCH_BSC = process.env.REACT_APP_GRAPH_FETCH_BSC;
export const GRAPH_FETCH_MATIC = process.env.REACT_APP_GRAPH_FETCH_MATIC;
export const GRAPH_FETCH_AVALANCHE = process.env.REACT_APP_GRAPH_FETCH_AVALANCHE;

export const IPFS_ENDPOINT = process.env.REACT_APP_IPFS_ENDPOINT;